// eslint-disable-next-line @nx/enforce-module-boundaries
import dynamic from 'next/dynamic';

export default {
  // Navigation
  BreadCrumbs: dynamic(() => import('@sitecore/components/content/Breadcrumbs/Breadcrumbs')),
  FooterMenu: dynamic(() => import('@sitecore/components/content/Footer/FooterMenu')),
  Footer: dynamic(() => import('@sitecore/components/content/Footer/Footer')),
  LanguageSelector: dynamic(() => import('@sitecore/components/content/LanguageSelector/LanguageSelector')),
  Logo: dynamic(() => import('@sitecore/components/eneco/ImageLink/ImageLink')),
  MainNavigation: dynamic(() => import('@apps/header/shared/multilabel/src/Header')),
  MegaMenu: dynamic(() => import('@sitecore/components/content/MegaMenu/MegaMenu')),
  MegaMenuV2: dynamic(() => import('@sitecore/components/content/MegaMenuV2/MegaMenuV2')),
  MegaMenuDivision: dynamic(() => import('@sitecore/components/content/MegaMenuDivision/MegaMenuDivision')),
  MessageBar: dynamic(() => import('@sitecore/components/content/MessageBar/MessageBar')),
  NotificationsBar: dynamic(() => import('@sitecore/components/content/NotificationsBar/NotificationsBar')),
  SubFooterMenu: dynamic(() => import('@sitecore/components/content/Footer/SubFooterMenu')),
  SocialMediaMenu: dynamic(() => import('@sitecore/components/content/Footer/SocialMediaMenu')),

  // Content components
  ContentCategories: dynamic(() => import('@sitecore/components/content/ContentCategories/ContentCategories')),
  ChatLinks: dynamic(() => import('@sitecore/components/content/ChatLinks/ChatLinks')),
  Accordion: dynamic(() => import('@sitecore/components/content/Accordion/Accordion')),
  Hero: dynamic(() => import('@sitecore/components/content/Hero/Hero')),
  NotificationBox: dynamic(() => import('@sitecore/components/content/NotificationBox/NotificationBox')),
  RichText: dynamic(() => import('@sitecore/components/content/RichText/RichText')),
  TopTasks: dynamic(() => import('@sitecore/components/content/TopTasks/TopTasks')),
  ContactCustomerService: dynamic(
    () => import('@sitecore/components/content/ContactCustomerService/ContactCustomerService'),
  ),
  Image: dynamic(() => import('@sitecore/components/content/Image/Image')),
  List: dynamic(() => import('@sitecore/components/content/List/List')),
  HeroCard: dynamic(() => import('@sitecore/components/content/HeroCard/HeroCard')),
  LinkCard: dynamic(() => import('@sitecore/components/content/LinkCard/LinkCard')),
  StoreButtons: dynamic(() => import('@sitecore/components/content/StoreButtons/StoreButtons')),
  SimpleTable: dynamic(() => import('@sitecore/components/content/SimpleTable/SimpleTable')),
  TextStreamer: dynamic(() => import('@sitecore/components/content/TextStreamer/TextStreamer')),
  UsabillaFeedback: dynamic(() => import('@sitecore/components/system/UsabillaFeedback/UsabillaFeedback')),
  WeDoenHetNuTestimonialsCard: dynamic(
    () => import('@sitecore/components/content/WeDoenHetNuTestimonialsCard/WeDoenHetNuTestimonialsCard'),
  ),
  AddressFinder: dynamic(() => import('@sitecore/components/content/AddressFinder/AddressFinder')),
  StepExplainer: dynamic(() => import('@sitecore/components/content/StepExplainerVertical/StepExplainerVertical')),
  CreditWarningBar: dynamic(() => import('@sitecore/components/content/CreditWarningBar/CreditWarningBar')),
  ShareOnSocials: dynamic(() => import('@sitecore/components/content/ShareOnSocials/ShareOnSocials')),
  ButtonLink: dynamic(() => import('@sitecore/components/content/ButtonLink/ButtonLink')),
  ButtonLineUp: dynamic(() => import('@sitecore/components/content/ButtonLineUp/ButtonLineUp')),
  Price: dynamic(() => import('@sitecore/components/content/Price/Price')),
  IconList: dynamic(() => import('@sitecore/components/content/IconList/IconList')),
  ContentHubFilter: dynamic(() => import('@sitecore/components/content/ContentHubFilter/ContentHubFilter')),
  FlourishChart: dynamic(() => import('@sitecore/components/content/FlourishChart/FlourishChart')),

  // Apps
  CustomerReview: dynamic(() => import('@apps/customerreview/shared/multilabel/src/CustomerReview')),
  DynamicPricingTariffs: dynamic(
    () => import('@apps/dynamicpricingtariffs/shared/multilabel/src/DynamicPricingTariffs/DynamicPricingTariffs'),
  ),

  // Other
  CookieWall: dynamic(() => import('@apps/cookiewall/nl/multilabel/src/CookieWall')),
  ErrorPage: dynamic(() => import('@sitecore/components/content/ErrorPage/ErrorPage')),
  GTM: dynamic(() => import('@apps/gtm/shared/multilabel/src')),
  Vwo: dynamic(() => import('@sitecore/components/system/Vwo/Vwo')),
  Hotjar: dynamic(() => import('@sitecore/components/system/Hotjar/Hotjar')),
  MetaData: dynamic(() => import('@sitecore/components/system/MetaData/MetaData')),
  MetaTags: dynamic(() => import('@sitecore/components/system/MetaTags/MetaTags')),
  StructuredDataSchema: dynamic(() => import('@sitecore/components/system/StructuredDataSchema/StructuredDataSchema')),
  Chat: dynamic(() => import('@apps/chat/shared/multilabel/src/Chat')),
  ContractConfirmation: dynamic(() => import('@sitecore/components/content/ContractConfirmation/ContractConfirmation')),

  // Wrapper components
  Article: dynamic(() => import('@sitecore/components/content/Article/Article')),
  Centered: dynamic(() => import('@sitecore/components/content/Centered/Centered')),
  Section: dynamic(() => import('@sitecore/components/content/Section/Section')),
  LineUp: dynamic(() => import('@sitecore/components/content/LineUp/LineUp')),
  NavigationView: dynamic(() => import('@sitecore/components/content/NavigationView/NavigationView')),
  SplitView: dynamic(() => import('@sitecore/components/content/SplitView/SplitView')),
  Carousel: dynamic(() => import('@sitecore/components/content/Carousel/Carousel')),

  // Wrappable components
  ArticleCard: dynamic(() => import('@sitecore/components/content/ArticleCard/ArticleCard')),
  ArticleContentCard: dynamic(() => import('@sitecore/components/content/ArticleContentCard/ArticleContentCard')),
  ContentCard: dynamic(() => import('@sitecore/components/content/ContentCard/ContentCard')),
  ProductCard: dynamic(() => import('@sitecore/components/content/ProductCard/ProductCard')),
  StickyNav: dynamic(() => import('@sitecore/components/content/StickyNav/StickyNav')),
  StukjeZonCalculationTool: dynamic(() => import('@sitecore/components/content/StukjeZon/StukjeZon')),
  Table: dynamic(() => import('@sitecore/components/content/Table/Table')),
  TitleTextCTA: dynamic(() => import('@sitecore/components/content/TitleTextCTA/TitleTextCTA')),
  PersonalizedTitleTextCTA: dynamic(
    () => import('@sitecore/components/content/PersonalizedTitleTextCTA/PersonalizedTitleTextCTA'),
  ),
  Checklist: dynamic(() => import('@sitecore/components/content/Checklist/Checklist')),
  USPItem: dynamic(() => import('@sitecore/components/content/USPItem/USPItem')),
  Video: dynamic(() => import('@sitecore/components/content/Video/Video')),

  // EnergyRating
  RegistrationAccountCreate: dynamic(
    () => import('@apps/accessmanagement/nl/multilabel/src/components/RegistrationFlow/RegistrationFlow'),
  ),
  RegistrationAccountReset: dynamic(
    () => import('@apps/accessmanagement/nl/multilabel/src/components/RegistrationFlow/RegistrationFlow'),
  ),
  RegistrationFooter: dynamic(
    () => import('@apps/accessmanagement/nl/multilabel/src/components/RegistrationFooter/RegistrationFooter'),
  ),
  RegistrationHeader: dynamic(
    () => import('@apps/accessmanagement/nl/multilabel/src/components/RegistrationHeader/RegistrationHeader'),
  ),

  // EmailRequest
  EmailRequest: dynamic(() => import('@components/EmailRequest/EmailRequest')),

  // Campaign components
  WeDoenHetNuCard: dynamic(() => import('@sitecore/components/content/Road2Zero/WeDoenHetNuCard/WeDoenHetNuCard')),
  WeDoenHetNuTextImage: dynamic(
    () => import('@sitecore/components/content/Road2Zero/WeDoenHetNuTextImage/WeDoenHetNuTextImage'),
  ),

  // Forms
  ContactForm: dynamic(() => import('@sitecore/components/forms/ContactForm/ContactForm')),
  ServiceSignupForm: dynamic(() => import('@sitecore/components/forms/ServiceSignupForm/ServiceSignupForm')),
  HeatDamageForm: dynamic(() => import('@sitecore/components/forms/HeatDamageForm/HeatDamageForm')),
  RegisterToNewsletterForm: dynamic(
    () => import('@sitecore/components/forms/RegisterToNewsletterForm/RegisterToNewsletterForm'),
  ),
  EmailRequestWrapper: dynamic(() => import('@sitecore/components/forms/EmailRequestWrapper/EmailRequestWrapper')),
};
